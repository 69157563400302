<template>
  <div>
    <el-card v-show="progressShow">
      <h4>文件名：{{ fileName }}</h4>
      <transition name="el-zoom-in-center">
      <span style="font-size: 14px;text-align: center;margin-top: 4px;color: #67C23A"
            v-show="progressTextShow">{{ progressText }}</span>
      </transition>
      <transition name="el-zoom-in-center">
        <el-progress :percentage="percentage"></el-progress>
      </transition>
    </el-card>

    <br>
    <!--   table列表     -->
    <div class="default-table">
      <el-table :data='dataList' :loading="loadingStatus" border>
        <el-table-column align='center' label='序号' type='index' width='50'/>
        <el-table-column show-overflow-tooltip align='center' label='标题' prop='title'></el-table-column>
        <el-table-column align='center' label='状态' prop='status'></el-table-column>
        <el-table-column align='center' label='总记录数' prop='rows_count'></el-table-column>
        <el-table-column show-overflow-tooltip align='center' label='用户' prop='nickname'></el-table-column>
        <el-table-column align='center' v-if="routerType.includes(uploadType)" label='导入数' prop='import_count'>
          <template slot-scope='{row}'>
            <el-link @click='detailPage(row.id)' size='mini'
                     type='primary'>
              <span>{{ row.import_count }}</span>
            </el-link>
          </template>
        </el-table-column>
        <el-table-column align='center' label='导入数' prop='import_count' v-else></el-table-column>
        <el-table-column align='center' label='耗时(s)' prop='use_time'></el-table-column>
        <el-table-column align='center' label='备注' show-overflow-tooltip prop='remark'></el-table-column>
        <el-table-column show-overflow-tooltip align='center' label='创建时间' prop='created_at'></el-table-column>
        <el-table-column show-overflow-tooltip align='center' label='修改' prop='updated_at'></el-table-column>
        <el-table-column align='center' label='操作' prop='updated_at'>
          <template slot-scope="{row}">
            <export-link :ref="`refExportLink_${row.id}`" :title="`下载当前文件`"
                         :lint-title="`下载`" :class-name="`export-btn`"
                         v-show="row.is_read&&row.is_read===1"
                         :can-export="userPermissions.indexOf('kol_info_excel')>-1||userPermissions.indexOf('oa_manage_import') !== -1"
                         @export="exportData(row)"/>
          </template>
        </el-table-column>
      </el-table>
      <el-row justify="right" type="flex">
        <el-col :span="24">
          <Pagination
              :limit.sync="pagingData.page_size"
              :page.sync="pagingData.current_page"
              :total="pagingData.total"
              @pagination="initList"/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import ApeTable from '@/components/ApeTable.vue'
import ExportLink from '@/components/export/ExportLink.vue'

export default {
  name: 'DownloadFileList',
  components: {
    PageHeaderLayout,
    ApeTable,
    ExportLink
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  props: {
    progressTextShow: {
      type: Boolean,
      default: false
    },
    progressText: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    },
    progressShow: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 0
    },
    reloadData: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: String,
      default: 'kol_resource'
    }
  },
  watch: {
    // progressShow: {
    //   // deep:true,
    //   immediate: true,
    //   handler(val) {
    //     if (!val) {
    //       this.initList()
    //     }
    //   }
    // },
    reloadData: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initList()
        }
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        page_size: 15,
        current_page: 1,
        total: 0
      },
      searchCondition: { status: null, source_type: null },
      options: { status: {}, source_types: {} },
      orderSort: { id: 'desc' },//默认排序规则
      routerType: ['oa', 'labor_cost', 'rebate', 'mcn_promotion', 'live_put']
    }
  },
  methods: {

    // // 切换页码操作
    // async switchPaging() {
    //   await this.initList()
    // },
    // changeTableSort({ prop, order }) {
    //   let orderSort = {}
    //   if (order) {
    //     orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
    //   }
    //   this.orderSort = orderSort
    //   this.search()
    // },

    // 初始化列表
    async initList() {
      this.loadingStatus = true
      // let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = {}
      Object.assign(searchCondition, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      }, { type: this.uploadType })
      let data = await this.$api.getUploadFileList(searchCondition)
      data.list.forEach((item) => {
        item.type = item.type === 'kol_resource' ? '红人资源库导入' : item.type
        item.status = item.status === 1 ? '未开始' : item.status === 2 ? '进行中' : '已完成'

      })
      this.dataList = data.list
      this.pagingData = data.page_info
      this.loadingStatus = false
    },
    async exportData(row) {
      try {
        let name = `${row.title}`
        let response = await this.$api.exportUploadFileList(row.id)
        this.$refs[`refExportLink_${row.id}`].exportSaveXlsx(response, name)
        row.download_count += 1
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },

    detailPage(id) {
      this.initList()

      switch (this.uploadType) {
        case 'oa' :
          this.oaRouter(id)
          break
        case  'labor_cost':
          this.laborCost(id)
          break
        case  'rebate':
          this.rebate(id)
          break
        case 'mcn_promotion':
          this.mcnRouter(id)
          break
        case 'live_put':
          this.liveRouter(id)
          break
        case 'businessSupportTeam':
          this.businessSupportTeam(id)
      }
      this.$emit('closeDialog', true)
    },
    liveRouter(id) {
      const { href } = this.$router.resolve({
        name: 'live_funding_import',
        params: { id: id }
      })
      window.open(href, '_blank')
    },
    mcnRouter(id) {
      const { href } = this.$router.resolve({
        name: 'mcn_promotion_import',
        params: { id: id }
      })
      window.open(href, '_blank')
    },
    oaRouter(id) {
      const { href } = this.$router.resolve({
        name: 'oaTable',
        params: { id: id }
      })
      window.open(href, '_blank')
    },
    laborCost(id) {
      const { href } = this.$router.resolve({
        name: 'labor_cost_import',
        params: { id: id }
      })
      window.open(href, '_blank')
    },
    rebate(id) {
      const { href } = this.$router.resolve({
        name: 'rebate_import',
        params: { id: id }
      })
      window.open(href, '_blank')
    },
    businessSupportTeam(id) {
      const { href } = this.$router.resolve({
        name: 'business_support_team_import',
        params: { id: id }
      })
      window.open(href, '_blank')
    }
  },
  mounted() {
    this.initList()
  }
}
</script>

<style scoped>

</style>
